<template>
  <div id="ContasPagarNovoItemModal" class="contas-pagar-novo-item-modal">
    <v-card class="contas-pagar-novo-item-modal__card container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
        <span class="white--text title-page body-1">{{ empreendcobritem_tp_cobr}}</span>
          <v-btn @click="fechar()" icon dark color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text class="wrapper pa-0">
        <div>
          <v-row class="mt-6 px-5">
            <v-col>
              <v-form ref="form">
                <fieldset class="d-flex flex-column rounded mb-2" style="border: none">
                  <!-- <pre>{{ itemForm.centrocusto }}</pre> -->
                  <v-row class="mt-n5">
                    <v-col cols="12" class="px-2">
                      <v-text-field
                        v-model="itemForm.empreendcobritem_descr"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        maxlength="200"
                        :rules="descRules"
                        autofocus
                        :readonly="empreendcobritem_tp_cobr == 'Multa' || empreendcobritem_tp_cobr == 'Juros' || empreendcobritem_tp_cobr == 'Multa / Juros'"
                        placeholder="Descrição"
                        label="Descrição" />
                    </v-col>
                  </v-row> 
                  <v-row class="mt-n5">
                    <v-col col="12" class="px-2">
                      <v-autocomplete
                        v-model="itemForm.centrocusto"
                        label="Centro de Custo"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        :items="array_centro_custo"
                        return-object
                        item-text="centrocusto_descricao"
                        item-key="cod_centrocusto" >
                        <template #append-outer>
                          <v-icon
                            class="pointer"
                            style="cursor: pointer"
                            color="green darken-1"
                            @click="cadastroCentroCusto()">mdi-plus
                          </v-icon>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n5">
                    <v-col col="12" class="px-2">
                      <v-autocomplete
                        v-model="itemForm.planoconta"
                        label="Plano de Conta"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        :items="array_plano_conta"
                        return-object
                        item-text="text"
                        item-value="text"                        
                        item-key="cod_planoconta"
                        >
                        <template v-slot:selection="data">
                          <span>{{data.item.planoconta_descricao}}</span>
                        </template>
                        <template v-slot:item="data">
                          <span :style="{'margin-left': (data.item.planoconta_codigocontabil.split('.').length-1)*20+'px' }">{{data.item.text}}</span>
                        </template>
                        <template #append-outer>
                          <v-icon
                            class="pointer"
                            style="cursor: pointer"
                            color="green darken-1"
                            @click="cadastroPlanoConta()">mdi-plus
                          </v-icon>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n5">
                    <v-col col="12" class="px-2">
                      <v-textarea
                        v-model="itemForm.empreendcobritem_obs"
                        rows="2"
                        background-color="#FFF"
                        label="Observação"
                        placeholder="Observação"
                        filled
                        dense
                        outlined
                        required
                        maxlength="200"
                        counter="200" />
                    </v-col>
                  </v-row>
                  <v-row class="mt-n5 justify-end align-center">
                  <div class="px-2 d-flex mt-4">
                    <v-checkbox
                      v-if="empreendcobritem_tp_cobr == 'Item'"
                      class="mt-0 mr-3"
                      v-model="itemForm.item_cre_deb"
                      hide-details
                      label="Desconto" />
                    <VuetifyMoney
                      type="tel"
                      v-model="itemForm.empreendcobritem_valor"
                      :rules="valorItemRules"
                      filled
                      dense
                      outlined
                      background-color="#FFF"
                      placeholder="Valor"
                      label="Valor"
                      reverse
                      class="contas-pagar-novo-item-modal__item-value-input font-weight-bold"
                      :class="valueInputColor" />
                  </div>
                  </v-row>
                </fieldset>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-footer
        absolute
        color="#F2F6F7"
        elevation="0"
        class="d-flex justify-center pa-3">
        <v-btn
          @click="fechar"
          class="mr-4 btn caption font-weight-medium"
          color="primary"
          text>
          Cancelar
        </v-btn>
        <v-btn
          @click="save"
          class="btn white--text caption font-weight-medium"
          color="primary accent-4"
          :disabled="isSaveButtonDisabled">
          Salvar<!-- {{ saveButtonLabel }} -->
        </v-btn>
      </v-footer>
    </v-card>

    <v-container v-if="dialog_CentroCustoModal">
      <v-dialog
          persistent
          v-model="dialog_CentroCustoModal"
          transition="dialog-bottom-transition"
          max-width="500">
        <CentroCustoModal
          :dialog_CentroCustoModal.sync="dialog_CentroCustoModal"
          @atualizarCentroCusto="opcoesCentroDeCusto"
        />
      </v-dialog>
    </v-container>
   
    <v-container v-if="dialog_PlanoContaModal">
      <v-dialog
          persistent
          v-model="dialog_PlanoContaModal"
          transition="dialog-bottom-transition"
          max-width="500">
        <PlanoContaModal
          :dialog_PlanoContaModal.sync="dialog_PlanoContaModal"
          :array_plano_conta="array_plano_conta"
          @atualizarPlanoConta="opcoesPlanoDeConta"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
  import { API } from '../../../services/API'
  import store_site from "../../../../src/store/store_site"
  import {
    COR_PRINCIPAL,
    COR_SECUNDARIA,
    COR_SUBTITULO,
    MASK_CPFCNPJ,
    MASK_TELEFONE,
  } from "../../../services/constantes";
  import VuetifyMoney from "../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
  import CentroCustoModal from "./CentroCustoModal.vue"
  import PlanoContaModal from "./PlanoContaModal.vue"

  export default {
    components: {
      VuetifyMoney,
      CentroCustoModal,
      PlanoContaModal
    },

    props: ['valorParcela', 'selectedEditItem', 'codCobranca', 'empreendcobritem_tp_cobr'],

    data () {
      return {
        COR_PRINCIPAL: COR_PRINCIPAL,
        COR_SECUNDARIA: COR_SECUNDARIA,
        COR_SUBTITULO: COR_SUBTITULO,
        MASK_CPFCNPJ: MASK_CPFCNPJ,
        MASK_TELEFONE: MASK_TELEFONE,
        disableSaveButton: false,
        store_site: store_site,
        dialog_CentroCustoModal : false,
        dialog_PlanoContaModal  : false,
        itemForm: {
          cod_empreendcobranca : '',
          empreendcobritem_descr : '',
          centrocusto : '',
          planoconta : '',
          empreendcobritem_valor : '',
          empreendcobritem_obs : '',
          item_cre_deb : false,
          empreendcobritem_manual : "S",
          empreendcobritem_estornar : "S",
          item_tipo : 'P',
        },
        array_centro_custo : [],
        array_plano_conta  : [],
        valorItemRules: [
          (value) => {
            if (this.itemForm?.item_cre_deb) {
              const desconto = +value?.replace(".", "").replace(",", ".")
              
              if (desconto > this.valorParcela) {
                this.disableSaveButton = true
                return "Desconto não pode ser maior que a parcela"
              } else {
                this.disableSaveButton = false
                return ''
              }
            }

            this.disableSaveButton = false
            return ''
          }
        ],
        descRules: [
          (value) => !!value || "A Descrição é obrigatória",
        ],
        obsRules: [
          (value) => !!value || "A Observação é obrigatório",
        ]
      }
    },

    created () {
      if (Object.keys(this.selectedEditItem).length){
        this.itemForm = { 
          ...this.selectedEditItem,
          item_cre_deb: this.selectedEditItem.item_cre_deb === 'C' ? true : false,

        }
        // if (condition) {
          
        // }
        // console.log("🚀 ~ file: ContasPagarNovoItemModal.vue:160 ~ created ~ this.itemForm:", this.itemForm)
      } 
        
      // console.log("🚀 ~ file: ContasPagarNovoItemModal.vue:267 ~ created ~ this.itemForm.empreendcobritem_tp_cobr:", this.itemForm)
      if(this.empreendcobritem_tp_cobr == 'Desconto'){
        this.itemForm.item_cre_deb = true
      }
      
    },
    
    mounted(){
      this.opcoesCentroDeCusto()
      this.opcoesPlanoDeConta()

      if(this.empreendcobritem_tp_cobr == 'Juros' || this.empreendcobritem_tp_cobr == 'Multa' || this.empreendcobritem_tp_cobr == 'Multa / Juros')
        this.itemForm.empreendcobritem_descr = this.empreendcobritem_tp_cobr
    },

    computed: {
      saveButtonLabel () {
        return this.isEdit ? 'Editar' : 'Salvar' 
      },

      isEdit () {
        return Object.keys(this.selectedEditItem).length > 0
      },

      isSaveButtonDisabled () {
        if (this.disableSaveButton) return true

        const requiredFields = ['empreendcobritem_descr', 'empreendcobritem_valor']
        let hasEmpty = false

        Object.entries(this.itemForm).forEach(([key, value]) => {
          if (key === 'empreendcobritem_valor' && value == 0) hasEmpty = true 

          if (requiredFields.includes(key) && !value) hasEmpty = true
        })

        return hasEmpty
      },

      valueInputColor () {
        return this.itemForm.item_cre_deb ? 'has-discount' : 'no-discount'
      }
    },

    methods: {
      cadastroCentroCusto(){
        this.dialog_CentroCustoModal = true
      },

      cadastroPlanoConta(){
        this.dialog_PlanoContaModal = true
      },

      async opcoesCentroDeCusto (item = null){
        let {data} = await API.get('/erp/centro_custo')
        this.array_centro_custo =  data.result

        if(!!item?.cod_centrocusto){
          this.itemForm.centrocusto = {...item}
        }
      },
      
      async opcoesPlanoDeConta (item = null){
        let {data} = await API.get('/erp/plano_conta')
        
        if(data.result.length > 0) {
          this.array_plano_conta = data.result.map(elem => {
            return {
              cod_planoconta : elem.cod_planoconta,
              planoconta_codigocontabil : elem.planoconta_codigocontabil,
              planoconta_descricao : elem.planoconta_descricao,
              text : `${elem.planoconta_codigocontabil} - ${elem.planoconta_descricao}`
            }
          })
        }

        if(!!item?.cod_planoconta){
          item.planoconta_descricao = `${item.planoconta_codigocontabil} - ${item.planoconta_descricao}`
          this.itemForm.planoconta = {...item}
        }
      },

      buildTree(data, parentCode) {
        const tree = [];
        for (const item of data) {
          if (item.cod_planoconta_rel === parentCode) {
            const children = this.buildTree(data, item.cod_planoconta);
            if (children.length > 0) {
              item.children = children;
            }
            tree.push(item);
          }
        }
        console.log("🚀 ~ file: ContasPagarNovoItemModal.vue:368 ~ buildTree ~ tree:", tree)
        return tree;
      },

      fechar() {
        this.resetForm()
        this.$emit("update:dialog_item_novo", false);
      },

      async save() {
        const credOuDeb = this.itemForm.item_cre_deb ? 'C' : 'D'
        this.itemForm.empreendcobritem_tp_cobr = this.empreendcobritem_tp_cobr
        // console.log('this.itemForm',this.itemForm);
        // return

        if(this.itemForm?.centrocusto?.cod_centrocusto){
          this.itemForm.cod_centrocusto = this.itemForm.centrocusto.cod_centrocusto;
        }
        if(this.itemForm?.planoconta?.cod_planoconta){
          this.itemForm.cod_planoconta = this.itemForm.planoconta.cod_planoconta;
        }

        // console.log("🚀 ~ file: ContasPagarNovoItemModal.vue:350 ~ save ~ this.isEdit:", this.isEdit)
        //let response = null
        if (this.isEdit) {
          // if (this.selectedEditItem.cod_empreendcobranca_item) {
          //   response = await API.put(`/erp/contaspagar/contaspagar_item/${this.selectedEditItem.cod_empreendcobranca_item}`, { ...this.itemForm, item_cre_deb: credOuDeb })
          // }

          this.itemForm.cod_empreendcobranca = this.codCobranca;
        
          this.$emit("edit-item", { ...this.itemForm, item_cre_deb: credOuDeb })
        } else {
          // if (this.codCobranca) {
          //   response = await API.post('/erp/contaspagar/contaspagar_item', { 
          //     ...this.itemForm, 
          //     cod_empreendcobranca: this.codCobranca,
          //     item_cre_deb: credOuDeb
          //   })
          // }
          this.itemForm.cod_empreendcobranca = this.codCobranca;
       
          this.$emit("save-item", { ...this.itemForm, item_cre_deb: credOuDeb })
        }

        this.fechar()

        this.resetForm()

        // if (response?.data?.result?.status) {
        //   if (response.data.result.status == 200) {
        //     this.store_site.alert_cor       = "#00A000";
        //     this.store_site.alert_timeout   = 1500;
        //     this.store_site.alert_msg       = response.data.result.result.trim();
        //     this.store_site.alert           = true;
        //   } else {
        //     // Mensagem de exclusão não realizada
        //     this.store_site.alert_cor       = "#FF0000";
        //     this.store_site.alert_timeout   = 10000;
        //     this.store_site.alert_msg       = response.data.result.result.trim();
        //     this.store_site.alert           = true;
        //   }
        // } else {
          // this.store_site.alert_cor       = "#00A000";
          // this.store_site.alert_timeout   = 1500;
          // this.store_site.alert_msg       = `Item ${this.isEdit ? 'editado' : 'criado'} com sucesso!`;  
          // this.store_site.alert           = true;
        // }
      },

      resetForm () {
        this.itemForm = {
          cod_empreendcobranca : '',//codigo da parcela
          empreendcobritem_descr : '',//descrição da parcela
          cod_centrocusto : '',//codigo do centro de custo
          empreendcobritem_manual : "S",
          empreendcobritem_estornar : "S",
          cod_planoconta : '',//codigo do plano de conta
          item_tipo : 'P',
          empreendcobritem_valor : '',//valor da parcela
          empreendcobritem_obs : '',
          item_cre_deb : false, //D debito ou C credito
        }
      }
    },

    watch: {
      'itemForm.item_cre_deb' (newValue) {
        if (!newValue) this.disableSaveButton = false 
      }
    }

  }
</script>

<style lang="scss">
.contas-pagar-novo-item-modal {
  height: 450px;
  overflow: hidden;

  &__card {
    height: 100%;
  }

  &__item-value-input.no-discount input {
    color: #D32F2F !important; 
  }

  &__item-value-input.has-discount input {
    color: #388E3C !important;
  }
}
</style>