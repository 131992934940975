<template>
  <div id="ContasPagarModal" style="height:710px!important">
    <v-card class="container pa-0" style="height:710px!important">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
                    class="justify-space-between py-1 px-4">
        <div >
          <!--Cabeçalho com busca para a ação novo -->
          <div v-if="dados_editar.acao == 'NOVO'">
            <v-row>
              <v-col class="title-page" cols="5">Novo Documento</v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="proposta_incluir"
                  filled
                  dense
                  outlined
                  background-color="#FFF"
                  placeholder="Nº Proposta"
                  label="Nº Proposta"
                  maxlength="100" />
              </v-col>
              <v-col> 
                <v-btn class="text-white text-capitalize" :color="COR_PRINCIPAL" elevation="0" outlined tabindex="-1" @click="buscaProposta(proposta_incluir)">
                  <v-icon class="false-icon ml-3 mr-2" color="#FFF">mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <span class="title-page-menor ml-2 mr-2"> - </span><span class="title-page-menor">{{dados_editar.empreendcobr_descricao}} ( {{dados_editar.empreendcobr_parcela_inicial}} / {{dados_editar.empreendcobr_parcela_final}} )</span>
            <span v-if="dados_editar.empreendcobr_situacao == 'Pago'" class="status_ativo ml-3">
              {{ dados_editar.empreendcobr_situacao }}
            </span>
            <span v-if="dados_editar.empreendcobr_situacao == 'Aberto'" class="status_pendente ml-3">
              {{ dados_editar.empreendcobr_situacao }}
            </span>
            <span v-if="dados_editar.empreendcobr_situacao == 'Rescindido'" class="status_cancelado ml-3">
              {{ dados_editar.empreendcobr_situacao }}
            </span>
            <span v-if="dados_editar.empreendcobr_situacao == 'Inativo'" class="status_cancelado ml-3">
              {{ dados_editar.empreendcobr_situacao }}
            </span>
          </div>
          <!--Cabeçalho padrão para ações de pagar e de Edição -->
          <div v-if="dados_editar.acao != 'NOVO'">
            <span class="title-page">Documento nº {{dados_editar.cod_empreendcobranca}}</span>
            <span v-if="dados_editar.empreendcobr_situacao == 'Pago'" class="status_ativo ml-3">
              {{ dados_editar.empreendcobr_situacao }}
            </span>
            <span v-if="dados_editar.empreendcobr_situacao == 'Aberto'" class="status_pendente ml-3">
              {{ dados_editar.empreendcobr_situacao }}
            </span>
            <span v-if="dados_editar.empreendcobr_situacao == 'Rescindido'" class="status_cancelado ml-3">
              {{ dados_editar.empreendcobr_situacao }}
            </span>
            <span v-if="dados_editar.empreendcobr_situacao == 'Inativo'" class="status_cancelado ml-3">
              {{ dados_editar.empreendcobr_situacao }}
            </span>
          </div>
        </div>

        <v-btn @click="fechar()" icon dark color="#F2F6F7">
          <v-icon large class="title">mdi-close</v-icon>
        </v-btn>


      </v-card-title>
       
      <v-card-text class="wrapper pa-0 pagar-form" style="height:640px!important">
        <v-divider dark></v-divider>

        <div v-bind:style="{ 'background-color':COR_PRINCIPAL }" v-if="dados_editar.acao != 'NOVO'">
          <!-- Cabecalho -------------------- -->
          <v-row class="ml-1 mt-0">
            <v-col>
              <label
                style="color:white;font-size:15px;"
                class="mr-4">
                  {{ nomeFavorecido }}
              </label>
              <label
                class=""
                style="color:silver;font-size:14px;">CPF / CNPJ:
              </label>
              <label
                style="color:white;font-size:15px;"
                class="mr-4">
                  {{ cpfFavorecido ?? cnpjFavorecido }}
              </label>
              <label
                style="color:silver;font-size:14px;">
                Telefone:
              </label>
              <label
                style="color:white;font-size:15px;">
                {{ foneCelular1 }} {{ foneCelular2 }}
              </label>
            </v-col>
          </v-row>
          <v-row class="ml-1 mt-n5">
            <v-col>
              <label
                style="color:silver;font-size:14px;font-style: bold;">Empreendimento:
                </label>
                <label
                style="color:white;font-size:15px;"
                class="mr-5">
                {{dados_editar.emp_cobr?.empreend_nome}}
              </label>

              <label
                style="color:silver;font-size:14px;font-style: bold;">Fase:
                </label>
                <label
                style="color:white;font-size:15px;"
                class="mr-5">
                {{dados_editar.fase}}
              </label>
              
              <label
                style="color:silver;font-size:14px;">Tipo
                Documento:
                </label>
                <label
                style="color:white;font-size:15px;"
                class="mr-5">
                {{dados_editar.empreendcobr_tipo_custo}}
              </label>
              <label
                style="color:silver;font-size:14px;">Tipo
                Cobrança:
                </label>
                <label
                style="color:white;font-size:15px;"
                class="mr-5">
                {{dados_editar.empreendcobr_modalidade}}
              </label>
              <br>
              <label
                style="color:silver;font-size:14px;">Descrição:
                </label>
                <label
                style="color:white;font-size:15px;"
                class="mr-5">
                {{dados_editar.empreendcobr_descricao}}
              </label>
            </v-col>
          </v-row>
        </div>

        <!-- Dados ---------------------- -->
        <div>
          <v-row class="px-5">
            <v-col>
              <v-form ref="form" v-model="valid">
                <fieldset class="rounded" style="border: none">
                  <v-row class="mt-0">
                    <v-col col="12" class="mt-1 px-2">
                      <v-autocomplete
                        v-model="contaPagarForm.obj_devedor_pagador"
                        :loading="loading"
                        :search-input.sync="search_devedor"
                        :items="array_devedor"
                        :readonly="dados_editar.empreendcobr_situacao === 'Pago'"
                        hide-details
                        @change="selecionaDevedor"
                        class="mt-n2"
                        label="Devedor *"
                        placeholder="Devedor"
                        item-text="pessoa_nome" 
                        item-value="cod_pessoa"
                        auto-select-first  
                        :rules="devedorRules"
                        filled 
                        outlined 
                        dense 
                        return-object
                        background-color="map-deep-merge white" >
                        <template #append-outer>
                          <v-icon
                            class="pointer"
                            style="cursor: pointer"
                            color="grey darken-1"
                            :disabled="dados_editar.empreendcobr_situacao === 'Pago'"
                            @click="toggleSelectClientDialog('devedor')">mdi-magnify-plus</v-icon>
                        </template>
                      </v-autocomplete>
                      <span v-if="!isPagadorEqualToDevedor" class="red--text">O devedor é {{ nomeDevedor }}</span>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <!-- DESCRIÇAO DO DOCUMENTO -->
                    <v-col cols="8" class="px-2">
                      <v-text-field
                        v-model="contaPagarForm.empreendcobr_descricao"
                        filled
                        dense
                        outlined
                        class="mt-1"
                        background-color="#FFF"
                        placeholder="Descrição do documento"
                        label="Descrição do documento"
                        maxlength="100"
                        readonly />
                    </v-col>

                    <v-col cols="4" class="px-2">
                      <v-text-field
                        v-model="contaPagarForm.empreendcobr_nf"
                        class="mt-1"
                        background-color="#FFF"
                        label="Nota Fiscal"
                        placeholder="Nota Fiscal"
                        filled
                        dense
                        outlined
                        maxlength="30"
                        :readonly="dados_editar.empreendcobr_situacao === 'Pago'"
                        />
                    </v-col>
                   
                  </v-row> 
                  <v-row class="mt-0">
                      <!-- OBSERVAÇÃO -->
                      <v-col cols="8" class="px-2">
                        <v-textarea
                          v-model="contaPagarForm.empreendcobr_obs"
                          class="mt-n6"
                          rows="1"
                          auto-grow
                          background-color="#FFF"
                          label="Observação"
                          placeholder="Observação"
                          filled
                          dense
                          outlined
                          maxlength="100"
                          :readonly="dados_editar.empreendcobr_situacao === 'Pago'"
                          counter="100" />
                      </v-col>
                       <!-- VALOR PARCELA -->
                      <v-col col="6" class="px-2">
                        <VuetifyMoney
                          v-model="valorParcela"
                          class="mt-n6 input-text"
                          filled
                          dense
                          outlined
                          background-color="#FFF"
                          placeholder="Valor Parcela"
                          label="Valor Parcela"
                          :clearable="false"
                          readonly
                          reverse />
                      </v-col>
                  </v-row> 
                  <!-- <pre>{{ dados_editar }}</pre> -->
                  <v-row class="mt-n8 mb-6">
                    <v-col col="12" class="px-2">
                      <v-data-table 
                        class="rounded-lg tabela" 
                        flat 
                        :items="dados_editar.cob_item" 
                        :headers="itensTableHeaders" 
                        :loading="loading" 
                        :items-per-page="20" 
                        hide-default-header 
                        hide-default-footer 
                        loading-text="Carregando...  aguarde..." 
                        no-data-text="Nenhum Registro Encontrado" 
                        no-results-text="Nenhum Registro Encontrado">
                        <template v-slot:top>
                          <v-row class="d-flex align-center justify-between mt-n1">
                            <v-col col="12">
                              <v-toolbar height="40px" flat class="rounded-lg">
                                <p class="font-primary font-weight-bold text-center mt-3" :style="{ 'color':COR_SUBTITULO }">
                                  Itens *
                                </p>  
                                <v-spacer></v-spacer>
                                <div v-if="dados_editar.empreendcobr_situacao != 'Pago'">
                                  <v-btn
                                    v-show="ocultaBtn('Acréscimo')"
                                    style="padding: 2px; height: auto"
                                    @click="dialog_item_novo = true; selectedEditItem = {}; empreendcobritem_tp_cobr = 'Acréscimo'"
                                    class="pr-2 mr-2"
                                    elevation="0">
                                    <v-icon
                                      class="btn-icon-plus mr-1"
                                      color="green accent-4">mdi-plus</v-icon>
                                    <span
                                      class="font-weight-medium text-none"
                                      :style="{ 'color':COR_SUBTITULO }">Acréscimo</span>
                                  </v-btn>
                                  <v-btn
                                    v-show="ocultaBtn('Desconto')"
                                    style="padding: 2px; height: auto"
                                    @click="dialog_item_novo = true; selectedEditItem = {}; empreendcobritem_tp_cobr = 'Desconto'"
                                    class="pr-2 mr-2"
                                    elevation="0">
                                    <v-icon
                                      class="btn-icon-plus mr-1"
                                      color="green accent-4">mdi-plus</v-icon>
                                    <span
                                      class="font-weight-medium text-none"
                                      :style="{ 'color':COR_SUBTITULO }">Desconto</span>
                                  </v-btn>
  
                                  <v-btn
                                    v-show="ocultaBtn('Multa / Juros')"
                                    style="padding: 2px; height: auto"
                                    @click="dialog_item_novo = true; selectedEditItem = {}; empreendcobritem_tp_cobr = 'Multa / Juros'"
                                    class="pr-2 mr-2"
                                    elevation="0">
                                    <v-icon
                                      class="btn-icon-plus mr-1"
                                      color="green accent-4">mdi-plus</v-icon>
                                    <span
                                      class="font-weight-medium text-none"
                                      :style="{ 'color':COR_SUBTITULO }">Multa / Juros</span>
                                  </v-btn>
  
                                  <!-- <v-btn
                                    v-show="ocultaBtn('Juros')"
                                    style="padding: 2px; height: auto"
                                    @click="dialog_item_novo = true; selectedEditItem = {}; empreendcobritem_tp_cobr = 'Juros'"
                                    class="pr-2 mr-2"
                                    elevation="0">
                                    <v-icon
                                      class="btn-icon-plus mr-1"
                                      color="green accent-4">mdi-plus</v-icon>
                                    <span
                                      class="font-weight-medium text-none"
                                      :style="{ 'color':COR_SUBTITULO }">Juros</span>
                                  </v-btn> -->
                                  <v-btn
                                    style="padding: 2px; height: auto"
                                    @click="dialog_item_novo = true; selectedEditItem = {}; empreendcobritem_tp_cobr = 'Item'"
                                    class="pr-2"
                                    elevation="0">
                                    <v-icon
                                      class="btn-icon-plus mr-1"
                                      color="green accent-4">mdi-plus</v-icon>
                                    <span
                                      class="font-weight-medium text-none"
                                      :style="{ 'color':COR_SUBTITULO }">Item</span>
                                  </v-btn>
                                </div>
                              </v-toolbar>
                            </v-col>
                          </v-row>
                        </template>

                        <!-- Titulos da Grade Endereço -->
                        <template v-slot:header v-if="!isMobile">
                            <thead>
                              <tr>
                                <th v-for="h in itensTableHeaders" :key="h.value" :style="h.style_valor" :class="h.class" >
                                  <span>{{h.text}}</span>
                                </th>
                              </tr>
                            </thead>
                        </template>

                        <!-- Dados da Grade Itens -->
                        <template #item="{ item }">
                          <tr style="padding-top: 10px" v-if="!isMobile">
                            <td style="max-width: 40px" v-if="dados_editar.empreendcobr_situacao != 'Pago'">
                              <v-menu 
                                offset-y
                                >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn color="grey" dark icon v-bind="attrs" v-on="on" class="ml-n4">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>

                                <v-list>
                                  <v-list-item @click="editar(item)">
                                    <!-- Botao Editar -->
                                    <v-list-item-title>
                                      <v-icon
                                        color="green"
                                        class="mr-2 icon-menu">
                                        mdi-pencil
                                      </v-icon>
                                      Editar
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item @click="dialog_excluir_item = true; itemExcluirSelecionado = item">
                                    <v-list-item-title>
                                      <v-icon
                                        color="red"
                                        class="mr-2 icon-menu">
                                        mdi-delete
                                      </v-icon> 
                                      Excluir
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td>
                            <!-- <td><pre>{{ item }}</pre></td> -->
                            <td>{{ item.empreendcobritem_tp_cobr}}</td>
                            <td>
                              {{ item.empreendcobritem_descr}}
                            </td>
                            <td>
                              {{ item.centrocusto?.centrocusto_descricao  }}
                            </td>
                            <td>
                              {{ item.planoconta?.planoconta_descricao  }}
                            </td>
                            <td align="right" :class="getItemValueColor(item)">
                              {{ formatPrice(item.empreendcobritem_valor) }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                    
                  <!-- <v-row class="mt-n5">  
                    <v-col cols="8" class="pt-0 px-2">
                      <v-text-field
                        v-model="contaPagarForm.empreendcobr_parc_acres_obs"
                        :rules="obsAcresRules"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Descrição Acréscimo"
                        label="Descrição Acréscimo"
                        autofocus
                        maxlength="100"
                        :readonly="dados_editar.empreendcobr_situacao === 'Pago'" />
                    </v-col>

                    <v-col cols="4" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="contaPagarForm.empreendcobr_parc_acres_valor"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Valor Acréscimo"
                        label="Valor Acréscimo"
                        reverse
                        :readonly="dados_editar.empreendcobr_situacao === 'Pago'"
                        :clearable="dados_editar.empreendcobr_situacao !== 'Pago'"
                        @input="$refs.form.validate()" />
                    </v-col>
                  </v-row>   -->
                    
                  <!-- <v-row class="mt-n5">
                    <v-col cols="8" class="pt-0 px-2">
                      <v-text-field
                        v-model="contaPagarForm.empreendcobr_parc_desc_obs"
                        :rules="obsDescRules"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Descrição Desconto"
                        label="Descrição Desconto"
                        maxlength="100"
                        :readonly="dados_editar.empreendcobr_situacao === 'Pago'" />
                    </v-col>

                    <v-col cols="4" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="contaPagarForm.empreendcobr_parc_desc_valor"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Valor Desconto"
                        label="Valor Desconto"
                        reverse
                        :readonly="dados_editar.empreendcobr_situacao === 'Pago'"
                        :clearable="dados_editar.empreendcobr_situacao !== 'Pago'"
                        @input="$refs.form.validate()" />
                    </v-col>
                  </v-row>   -->

                  <v-row class="mt-n5">


                    <!-- VALOR MULTA -->
                    <v-col cols="4" class="pt-0 px-2">
                      <!-- <VuetifyMoney
                        v-model="contaPagarForm.empreendcobr_pagto_valor_multa"
                        :clearable="dados_editar.empreendcobr_situacao !== 'Pago'"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Multa"
                        label="Multa"
                        reverse
                        :readonly="dados_editar.empreendcobr_situacao === 'Pago'" /> -->
                    </v-col>
                  </v-row>  

                  <v-row class="mt-n5">
                 

                    <v-col cols="4" class="pt-0 px-2">
                      <!-- <VuetifyMoney
                        v-model="contaPagarForm.empreendcobr_pagto_valor_juros"
                        class="mt-1"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Juros"
                        label="Juros"
                        reverse
                        :readonly="dados_editar.empreendcobr_situacao === 'Pago'"
                        :clearable="dados_editar.empreendcobr_situacao !== 'Pago'" /> -->
                    </v-col>
                  </v-row>
                  <v-row style="justify-content: space-between;">
                    <v-col col="4" class="px-2"></v-col>
                     <!-- DATA VENCIMENTO -->
                    <v-col col="4" class="px-2">
                      <DatePicker
                        v-model="contaPagarForm.empreendcobr_parc_dt"
                        label="Data vencimento"
                        class="mt-n5"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense
                        readonly />
                    </v-col>
                    <v-col cols="4" class="px-2">
                      <VuetifyMoney
                        v-model="pagamentoValorFinal"
                        class="mt-n5 input-text"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Valor Final"
                        :clearable="false"
                        label="Valor Final"
                        reverse
                        readonly />
                    </v-col>
                  </v-row>

                  <div class="linha mt-n6">
                    <hr class="linha-horizontal">
                    <span class="texto-verde"><strong>Pagamento</strong></span>
                    <hr class="linha-horizontal">
                  </div>

                  <v-row class="mt-2">
                    <v-col cols="12" class="pt-0 px-2">
                      <v-data-table
                        class="rounded-lg tabela"
                        flat
                        :items="contaPagarForm.paymentTableData"
                        :headers="paymentTableHeaders"
                        :items-per-page="10"
                        hide-default-header
                        hide-default-footer
                        loading-text="Carregando...  aguarde..."
                        no-data-text="Nenhum Registro Encontrado"
                        no-results-text="Nenhum Registro Encontrado">
                        <template v-slot:top>
                          <v-row class="d-flex align-center justify-between mt-n1">
                            <v-col col="12">
                              <v-toolbar height="40px" flat class="rounded-lg">
                                <p class="font-primary font-weight-bold text-center mt-3" :style="{ 'color':COR_SUBTITULO }">
                                  Formas de Pagamento Escolhidas *
                                </p>  

                                <v-spacer></v-spacer>

                                <v-btn
                                  style="padding: 2px; height: auto"
                                  v-if="dados_editar.empreendcobr_situacao !== 'Pago'"
                                  @click="toggleDialogFormasPagamento"
                                  class="pr-2"
                                  elevation="0">
                                  <v-icon class="btn-icon-plus mr-1" color="green accent-4">mdi-plus</v-icon>
                                  <span class="font-weight-medium text-none" :style="{ 'color':COR_SUBTITULO }">Novo</span>
                                </v-btn>
                              </v-toolbar>
                            </v-col>
                          </v-row>
                        </template>

                        <!-- Titulos da Grade Endereço -->
                        <template v-slot:header v-if="!isMobile">
                            <thead>
                              <tr>
                                <th v-for="h in paymentTableHeaders" :key="h.value" :style="h.style_valor" :class="h.class" >
                                  <span >{{h.text}}</span>
                                </th>
                              </tr>
                            </thead>
                        </template>

                        <template #item="{ item }">
                          <tr style="padding-top: 10px" v-if="!isMobile">
                            <td
                              v-if="contaPagarForm.empreendcobr_situacao == 'Aberto'"
                              style="max-width: 10px; cursor: pointer; padding-right: 0">
                              <v-icon
                                size="20px"
                                color="gray"
                                class="icon-menu cursor-pointer"
                                @click="openModalExcluirFormaDePagamento(item)">mdi-delete</v-icon>
                            </td>
                            <td>{{ getTipoPagamento(item) }} ({{ item.empreendcobrpagto_descr  }})</td>
                            <td align="right">{{ formatPrice(item.empreendcobrpagto_valor) }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-row class="justify-end">
                    <!-- Documentos -->
                    <v-col v-if="dados_editar.acao != 'NOVO'" col="1" class="pt-0 px-2">
                      <v-btn
                        @click="documentos(dados_editar)"
                        elevation="0"
                        style="background-color: var(--COR_SECUNDARIA)!important;">
                        <v-icon
                          class="btn-icon-plus mr-1 mt-1"
                          color="green accent-4">mdi-badge-account</v-icon>
                      </v-btn>
                    </v-col>
                    <!-- DATA PAGAMENTO -->
                    <v-col col="1" class="pt-0 px-2">
                      <DatePicker
                        v-model="contaPagarForm.empreendcobr_pagto_dt"
                        label="Data Pagamento"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense
                        placeholder="dia/mês/ano"
                        :rules="dataPagtoRules"
                        :disabled="dados_editar.acao == 'EDITAR'"
                        :readonly="dados_editar.empreendcobr_situacao === 'Pago'" />
                    </v-col>
                    <v-col cols="4" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="valorPago"
                        filled
                        dense
                        class="input-text"
                        outlined
                        background-color="#FFF"
                        placeholder="Valor Recebido"
                        label="Valor Recebido"
                        reverse
                        :clearable="false"
                        readonly />
                    </v-col>
                  </v-row>
                  <v-row class="justify-end">
                    <v-col cols="4" class="mt-n6 pt-0 px-2">
                      <VuetifyMoney
                        v-show="valorTroco != 0"
                        v-model="valorTroco"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        :placeholder="calculoTroco"
                        :label="calculoTroco"
                        class="contas-pagar-novo-item-modal__item-value-input font-weight-bold input-text"
                        :class="valueInputColor"
                        :clearable="false"
                        reverse
                        readonly />
                    </v-col>
                  </v-row>
                </fieldset>  
              </v-form>
            </v-col>
          </v-row>
        </div>  

        <!-- DIALOG EXCLUIR ITEM -->
        <v-dialog :value="dialog_excluir_item" persistent max-width="430" class="d-flex justify-center">
          <v-divider></v-divider>

          <v-card elevation="0" class="d-flex justify-center flex-wrap mt-3 mb-3">
            <v-card-title class="text-h6 justify-center" style="width: 100%">
              ATENÇÃO<v-icon x-large color="red">mdi-alert-outline</v-icon>
            </v-card-title>
            <v-card-title class="text-h6" style="word-break: break-word">
              Esta operação é IRREVERSÍVEL, deseja continuar?
            </v-card-title>
            <v-card-actions class="d-flex justify-start">
              <v-btn class="btn white--text" color="error accent-4" @click="excluirItem">
                Excluir
              </v-btn>
              <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir_item = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Dialog Excluir Forma de pagamento ----------------------- -->
        <v-dialog
          v-model="modalExcluirFormaDePagamento"
          :value="modalExcluirFormaDePagamento"
          persistent
          max-width="500"
          class="d-flex justify-center">
          <v-divider></v-divider>

          <v-card elevation="0" class="mt-3 mb-3">
            <v-card-title class="text-h6">
              Tem certeza que deseja EXCLUIR o pagamento?
            </v-card-title>
            <v-card-actions class="d-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="modalExcluirFormaDePagamento = false">
                Cancelar
              </v-btn>

              <v-btn class="btn white--text" color="primary accent-4" :loading="loading_excluir_forma_pagto" @click="excluirFormaDePagamento">
                Excluir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-card-text>
      
      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn v-if="dados_editar.empreendcobr_situacao !== 'Pago'" @click="fechar()" class="mr-4 btn caption font-weight-medium" color="primary" text>
          Cancelar
        </v-btn>
        <v-btn v-if="dados_editar.empreendcobr_situacao === 'Pago'" @click="fechar()" class="mr-4 btn caption font-weight-medium" color="primary" text>
          Fechar
        </v-btn>
        <v-btn
          v-if="dados_editar.empreendcobr_situacao !== 'Pago'"
          @click="verificarRepasse()"
          :disabled="isSaveButtonDisabled"
          :loading="loading"
          class="btn white--text caption font-weight-medium"
          color="primary accent-4">
          Salvar
        </v-btn>
      </v-footer>
    </v-card>

    <!-- DIALOG PAGADOR -->
    <v-container v-if="dialogPagador">      
      <v-dialog
        persistent
        v-model="dialogPagador"
        scrollable
        max-width="500px"
        max-height="600px">
        <v-card>
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">Busca de Pagador</p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="dialogPagador = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do DEVEDOR -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobile class="mt-n2" :editar_="true" />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do DEVEDOR -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn @click="dialogPagador = false" class="mr-4 btn text-none" color="primary" text>
              Cancelar
            </v-btn>
            <v-btn :disabled="!store_Pessoa.pessoa_selecionado" class="btn text-none" color="primary" @click="selectPagador">
              Selecionar 
            </v-btn>
          </v-card-actions>
          
        </v-card>
      </v-dialog>
    </v-container>

    <!-- DIALOG MUDOU O PAGADOR E TEM FORMAS DE PAGAMENTOS ESCOLHIDAS -->
    <v-container v-if="dialog_mudou_pagador">      
      <v-dialog
        persistent
        v-model="dialog_mudou_pagador"
        scrollable
        max-width="550px">
        <v-card>
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">Pagador diferente do devedor</p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="mudouPagadorNao()" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do DEVEDOR -->
          <v-card-text class="pa-2">
            <div>
              Devido à mudança de pagador, haverá alterações nas formas de pagamento existentes para esta cobrança. 
              As formas de pagamento anteriormente associadas à conta do pagador anterior serão removidas.
            </div>
            <div>
              Caso deseje prosseguir, será necessário reconfigurar as formas de pagamento.
            </div><br>
            <div style="font-size: 15px;">
              <strong style="margin: 30%;">
                Gostaria de continuar?
              </strong>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <!-- Rodape do DEVEDOR -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn @click="mudouPagadorNao()" class="mr-4 btn text-none" color="primary" text>
              Não
            </v-btn>
            <v-btn class="btn text-none" color="primary" @click="mudouPagadorSim()">
              Sim 
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- <pre>{{ contaPagarForm }}</pre> -->
    <!-- FORMAS DE PAGAMENTO -->
    <v-container v-if="dialogFormasPagamento">
      <v-dialog
        persistent
        v-model="dialogFormasPagamento"
        transition="dialog-bottom-transition"
        max-width="600"
        min-width="520">
        <FormasDePagamentoModal
          class="mt-n2"
          :editar_="true"
          :cod_empreendcobranca="contaPagarForm.cod_empreendcobranca"
          :pagador="pagadorObj"
          :dialogFormasPagamento.sync="dialogFormasPagamento"
          :valorTroco="valorTroco"
          :cheques_escolhidos="cheques_escolhidos"
          :nomeTitular="dados_editar.devedor"
          :cedente_selecionado="cedente_selecionado"
          :dialog_lancamentos="dialog_lancamentos"
          :dados_editar="dados_editar"
          @chequeEditado="atualizaArrayPagador"
          @criaFormaDePagamento="saveFormaDePagamento" />
      </v-dialog>
    </v-container>
    
    <!--------- DIALOG REPASSE ------------------------------->
    <v-dialog v-model="dialog_criar_repasse" transition="dialog-bottom-transition" persistent max-width="460" class="pa-0">
      <v-card 
        v-bind:style="{ 'background-color':COR_SECUNDARIA }"
        class="container pa-0">
        <v-card-title 
          v-bind:style="{ 'background-color':COR_PRINCIPAL }"
          class="justify-space-between py-2 px-3">
          <span class="text-white title-page body-1">Repasse</span>
          <v-btn
            @click="dialog_criar_repasse = false"
            icon
            dark
            color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text class="pt-3">
            <span style="font-size: 15px; font-weight:600">
              O pagador original é diferente do pagador atual, deseja criar um repasse?
            </span>

            <DatePicker
              v-model="contaPagarForm.nova_dt_venc"
              label="Data vencimento"
              class="mt-1"
              outlined
              backgroundColor="#FFF"
              :style="data_picker"
              dense />
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn
              id="btn_cancelar"
              ref="btn_cancelar"
              
              color="primary"
              text
              @click="contaPagarForm.criar_repasse = false; dialog_criar_repasse = false">
              Cancelar
            </v-btn>
  
            <v-btn
              class="btn white--text"
              color="primary accent-4"
              width="160px"
              @click="contaPagarForm.criar_repasse = false; dialog_criar_repasse = false; pagar()"
            >
              Pagar (sem repasse)
            </v-btn>

            <v-btn
              :disabled="!validateDate()"
              class="btn white--text"
              color="primary accent-4"
              width="160px"
              @click="contaPagarForm.criar_repasse = true; dialog_criar_repasse = false; pagar()"
            >
              Pagar <span style="font-size:11px">(com repasse)</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!--------- FIM DIALOG REPASSE ------------------------------->

    <!-- DIALOGO ITENS -------------------------------------------------------->
    <v-container v-if="dialog_item_novo">
      <v-dialog
        persistent
        v-model="dialog_item_novo"
        transition="dialog-bottom-transition"
        max-width="600"
        min-width="520">
        <ContasPagarNovoItemModal
          v-if="dialog_item_novo"
          :dialog_item_novo.sync="dialog_item_novo"
          :selectedEditItem="selectedEditItem"
          :valorParcela="valorParcela"
          :codCobranca="contaPagarForm.cod_empreendcobranca"
          :empreendcobritem_tp_cobr="empreendcobritem_tp_cobr"
          @save-item="saveItem"
          @edit-item="editItem" />
      </v-dialog>
    </v-container>
    <!-- FIM DIALOGO ITENS ---------------------------------------------------->

    <!-- Dialog Documentos -->
    <v-container v-if="dialog_documentos">
      <v-dialog
        persistent
        v-model="dialog_documentos"
        transition="dialog-bottom-transition"
        width="80%">
        <v-card 
          :style="{ 'background-color':COR_SECUNDARIA }"
          class="container pa-0">
          <v-card-title 
            :style="{ 'background-color':COR_PRINCIPAL }"
            class="justify-space-between pa-2">
            <span class="text-white title-page body-1">Documentos</span>
            <v-btn
              @click="dialog_documentos = false"
              icon
              dark
              color="#F2F6F7">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <GedDocumentos
          :dados_documentos="dados_documentos"
          :cod_empreendcobranca_aux="cod_empreendcobranca_aux"
          :paramsTipoDocumento="paramsTipoDocumento"
          :paramBusca="paramBusca"
          :statusDocumento="statusDocumento"
          @fecharModal="dialog_documentos = false" />
      </v-dialog>
    </v-container>

     <!--------- DIALOG NOVO DOCUMENTO ------------------------------->
     <v-container v-if="dialog_GedNovoDocumentoModal">
        <v-dialog
          v-model="dialog_GedNovoDocumentoModal"
          persistent
          max-width="440px">
          <GedNovoDocumentoModal
            :paramsTipoDocumento="{tipo_cadastro: 'cobranca', tipo_cadastro_descricao: 'Documentos Pagar/Receber'}"
            :cod_empreendcobranca_aux="dados_editar.cod_empreendcobranca"
            :statusDocumento="'Aprovado'"
            @fecharModal="dialog_GedNovoDocumentoModal = false"
            @fecharModalPagar="fechar()" />
        </v-dialog>
      </v-container>
      <!--------- FIM DIALOG NOVO DOCUMENTO ------------------------------->

  </div>
</template>

<script>
import store_Pessoa from "../../Pessoas/store_Pessoa";
import store_ContasPagar from "../ContasPagar/store_ContasPagar"
import moment from 'moment'
import { ContasPagarGet } from "../../../services/erp"
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import VuetifyMoney from "../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
import store_Financeiro from "../store_Financeiro";
import ContasPagarNovoItemModal from './ContasPagarNovoItemModal.vue'
import { API } from "../../../services/API";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO, MASK_CPFCNPJ, MASK_TELEFONE, } from "../../../services/constantes";
import {
  CpfCnpjIsValid,
  telefoneIsValid,
  formatDate,
  maskCpfCnpj,
  formatPrice
} from "../../../services/funcoes";
import store_site from "../../../../src/store/store_site"
import ContasPagar from "./ContasPagar.vue";
import PessoaConsultaMobile from '../../Pessoas/PessoaConsultaMobile.vue'
import FormasDePagamentoModal from '../../Financeiro/ContasPagar/FormasDePagamentoModal.vue'
import GedDocumentos from "../../Ged/GedDocumentos.vue"
import GedNovoDocumentoModal from "../../Ged/GedNovoDocumentoModal.vue";

export default {
  name: "ContasPagarModal",

  props: ['dialog_novo_editar', 'dados_editar', 'cedente_selecionado', 'dialog_lancamentos'],

  components: {
    VuetifyMoney,
    DatePicker,
    PessoaConsultaMobile,
    FormasDePagamentoModal,
    ContasPagarNovoItemModal,
    GedDocumentos,
    GedNovoDocumentoModal,
  },

  data() {
    return {
      modalExcluirFormaDePagamento: false,
      loading_excluir_forma_pagto: false,
      store_Financeiro    : store_Financeiro,
      store_site          : store_site,
      store_Pessoa        : store_Pessoa,
      store_ContasPagar : store_ContasPagar,
      ContasPagar       : ContasPagar,
 
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      COR_SUBTITULO     : COR_SUBTITULO,  
      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,
      formatDate        : formatDate,
      maskCpfCnpj       : maskCpfCnpj,
      formatPrice       : formatPrice,
      selectedFormaDePagamento: {},
      cheques_escolhidos      : [],
      array_fases             : [],
      loading                 : false,
      search_devedor          : null,
      array_devedor           : [],
      empreendcobritem_tp_cobr : null,
      paramsTipoDocumento      : {},
      paramBusca               : {},

      dialog_item_novo             : false,
      dialogPagador                : false,
      dialogFormasPagamento        : false,
      dialog_excluir_item          : false,
      dialog_criar_repasse         : false,
      dialog_GedNovoDocumentoModal : false,
      devedor_pagador_aux : {
        cod_pessoa: null,
        pessoa_nome: null
      },
      dialog_mudou_pagador : false,

      dados_documentos          : {},
      cod_empreendcobranca_aux  : null,
      dialog_documentos         : false,

      contaPagarForm: {
        criar_repasse                 : false,
        cod_devedor_pagador           : '',
        pagador                       : '',
        cod_empreendcobranca          : '',
        empreendcobr_parc_valor       : 0,
        empreendcobr_parc_dt          : '',
        empreendcobr_parc_acres_obs   : '',
        empreendcobr_parc_acres_valor : 0,
        empreendcobr_parc_desc_obs    : '',
        empreendcobr_parc_desc_valor  : 0,
        empreendcobr_historico        : '',
        empreendcobr_pagto_valor_multa: 0,
        empreendcobr_pagto_valor_juros: 0,
        empreendcobr_obs              : '',
        empreendcobr_situacao         : '',
        empreendcobr_pagto_dt         : moment().format(),
        empreendcobr_pagto_valor      : 0,
        empreendcobr_troco            : 0,
        empreendcobr_parc_valor_final : 0,
        empreendcobr_descricao        : '',
        paymentTableData              : []
      },

      acresObsHasError: false,
      descObsHasError: false,

      selectedCobranca: null,

      proposta_incluir  : null,

      valid: true,
      form: false,

      devedorRules: [
        (value) => !!value || "O Devedor é obrigatório"
      ],
      
      dataPagtoRules: [
        (value) =>
          (value && value?.length >= 10) ||
          (this.dados_editar?.acao == "PAGAR" ? "Data deve ter ao mínimo 8 caracteres" : "")
        
      ],

      obsAcresRules: [
        value => {
          if (this.contaPagarForm?.empreendcobr_parc_acres_valor > 0 && !value) {
            return 'Descrição Acréscimo é obrigatório caso haja valor'
          }

          return true
        }
      ],

      obsDescRules: [
        value => {
          if (this.contaPagarForm?.empreendcobr_parc_desc_valor > 0 && !value) {
            return 'Descrição Desconto é obrigatório caso haja valor'
          }

          return true
        }
      ],

      observacaoRules: [
        (value) =>
          (value && value?.length < 100) ||
          "Observação deve ter menos do que 100 caracteres",
      ],

      data_picker : {
        'min-width':'150px !important',
      }

    }
  },

  watch: {
    search_devedor(query) {
      if (query && (!this.contaPagarForm?.obj_devedor_pagador || this.contaPagarForm?.obj_devedor_pagador?.pessoa_nome !== query)) {
        this.querySearch(query, 'devedor');
      }
    },

    'contaPagarForm.paymentTableData'(val){
      this.cheques_escolhidos = []
      val.forEach(formas => { 
        if (!!formas.cod_fin_cheque) {
          this.cheques_escolhidos.push(formas.cod_fin_cheque)
        }
      })
    }
  },

  computed: {
    valorParcela () {
      if (this.dados_editar.cob_item.length) {
        let array_item = []
        this.dados_editar.cob_item.map(el => {
          if(el.empreendcobritem_tp_cobr == 'Item')
            array_item.push(el)
        })
      
        return array_item.reduce((curr, prev) => { 
          if (prev.item_cre_deb === 'D') {
            return curr += +prev.empreendcobritem_valor
          }

          return curr -= +prev.empreendcobritem_valor
        }, 0)
      }

      return 0
    },

    itensTableHeaders () {
      const headers = [
        { text: 'Tipo', value: 'tipo' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Centro de Custo', value: 'centroDeCusto' },
        { text: 'Plano de Contas', value: 'planoDeConta' },
        { text: 'Valor', value: 'valor', style_valor: 'text-align: right' }
      ]
      
      if (this.dados_editar.empreendcobr_situacao == 'Aberto') headers.unshift({ text: '', value: '' })
      return headers
    },

    acresObsErrorStyle () {
      if (this.acresObsHasError) return 'border: 1px solid red'
      return ''
    },

    descObsErrorStyle () {
      if (this.descObsHasError) return 'border: 1px solid red'
      return ''
    },


    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    // Consiste validade do botão de Salvar
    isSaveButtonDisabled () {
      return  !this.valid || 
              !this.contaPagarForm.paymentTableData.length
              //|| parseFloat(this.valorPago.toFixed(2)) < parseFloat(this.pagamentoValorFinal.toFixed(2))
    },

    paymentTableHeaders () {
      const headers = [
        { text: 'Descrição', value: 'descricao' }, 
        { text: 'Valor', value: 'valor', style_valor: 'text-align: right' }
      ]

      if (this.contaPagarForm.empreendcobr_situacao == 'Aberto') headers.unshift({ text: '', value: '' })
      
      return headers
    },

    pagadorObj () {
      return this.contaPagarForm.cod_devedor_pagador
    },
    
    isPagadorEqualToDevedor () {
      return this.contaPagarForm.cod_devedor_pagador === this.dados_editar.cod_devedor_referencia
    },

    pagamentoValorFinal () {
      // return this.contaPagarForm.empreendcobr_parc_valor           
      //        + +this.contaPagarForm.empreendcobr_parc_acres_valor   
      //        - +this.contaPagarForm.empreendcobr_parc_desc_valor   
      //        + +this.contaPagarForm.empreendcobr_pagto_valor_multa 
      //        + +this.contaPagarForm.empreendcobr_pagto_valor_juros 

      if (this.contaPagarForm?.cob_item?.length) {
        return this.contaPagarForm.cob_item.reduce((curr, prev) => { 
          if (prev.item_cre_deb === 'D') {
            return curr += + parseFloat(prev.empreendcobritem_valor)
          }

          return curr -= + parseFloat(prev.empreendcobritem_valor)
        }, 0)
      } else
      return 0
    },

    valorPago () {
      return this.contaPagarForm.paymentTableData?.reduce((curr, prev) => {
        return curr += +prev.empreendcobrpagto_valor
      }, 0) || 0
    },

    valorTroco () {
      const troco = this.valorPago.toFixed(2) - this.pagamentoValorFinal.toFixed(2)
      return troco.toFixed(2) 
    },

    nomeDevedor () {
      return this.dados_editar?.cobr_dev?.pessoa_nome
    },

    nomeFavorecido () {
      return this.dados_editar?.cobr_pess?.pessoa_nome
    },

    cpfFavorecido () {
      return this.dados_editar?.cobr_pess?.pessoa_cpf
    },

    cnpjFavorecido () {
      return this.dados_editar?.cobr_pess?.pessoa_cnpj
    },

    foneCelular1 () {
      return this.dados_editar?.cobr_pess?.fone_celular1
    },

    foneCelular2 () {
      return this.dados_editar?.cobr_pess?.fone_celular2
    },

    calculoTroco(){
      let labelTroco = this.valorPago - this.pagamentoValorFinal
      return labelTroco < 0 ? 'Restante' : 'Troco'
    },

    valueInputColor(){
      let labelTroco = this.valorPago - this.pagamentoValorFinal
      if (labelTroco < 0) {
        labelTroco = 'no-discount'
      }
      else if(labelTroco > 0){
        labelTroco = 'has-discount'
      }

      return labelTroco
    },
  },

  async created () {
   
    try {
      await store_ContasPagar.ContasPagarFormasDePagamento()
    } catch (e) {
      console.log(e)
    }

    this.array_devedor = [
      {
        cod_pessoa : this.dados_editar.cobr_dev.cod_pessoa,
        pessoa_nome : this.dados_editar.cobr_dev.pessoa_nome
      }]
     
    this.contaPagarForm = {
      ...this.dados_editar,
      empreendcobr_pagto_dt: moment().format(),
      paymentTableData: this.dados_editar.cobr_pagto || [],
      obj_devedor_pagador : { cod_pessoa : this.dados_editar.cobr_dev.cod_pessoa, pessoa_nome : this.dados_editar.cobr_dev.pessoa_nome}
    }

    if(!!this.cedente_selecionado){
      this.contaPagarForm.empreendcobr_pagto_dt = moment(this.dialog_lancamentos.data).format()
    }
  },

  async mounted(){
    await this.buscaFases()
    await this.ordenaItens()
    let lo_fase = this.array_fases?.find(val => val.cod_empreend_fase === this.dados_editar.cod_empreend_fase)
    this.dados_editar.fase = lo_fase?.nome_fase
  },

  methods: {
    documentos(item) {
      this.dados_documentos = {...item}
      this.dados_documentos.tipo = 'CONTAS A PAGAR';
      this.paramsTipoDocumento = {tipo_cadastro: 'cobranca', tipo_cadastro_descricao: "Documentos Pagar/Receber"}
      this.paramBusca = {cod_empreendcobranca : item.cod_empreendcobranca}
      this.statusDocumento = 'Aprovado'
      this.cod_empreendcobranca_aux = item.cod_empreendcobranca
      this.dialog_documentos = true
    },

    editar(item) {
      let indice = this.dados_editar.cob_item.indexOf(item)
      // console.log("🚀 ~ file: ContasPagarModal.vue:1287 ~ editar ~ indice:", indice, item)
      item.indice = indice
      this.empreendcobritem_tp_cobr = item.empreendcobritem_tp_cobr
      this.selectedEditItem = { ...item }
      this.dialog_item_novo = true
    },

    async excluirItem () {
      const index = this.dados_editar.cob_item.indexOf(this.itemExcluirSelecionado )
     
      this.dados_editar.cob_item.splice(index, 1)
      this.dialog_excluir_item = false
    },

    ocultaBtn(tipoBtn){
      if(this.dados_editar?.cob_item?.length > 0){
        var lo_array_btn = this.dados_editar?.cob_item.find(elem => elem.empreendcobritem_tp_cobr == tipoBtn)
        
        if(lo_array_btn){
          // console.log("🚀 ~ file: ContasPagarModal.vue:1245 ~ ocultaBtn ~ lo_array_btn:", lo_array_btn)
          return false
        }else{
          return true
        }
      }
      return true      
    },

    ordenaItens(){
      this.dados_editar.cob_item.sort((a, b) => {
        if (a.empreendcobritem_tp_cobr === "Item" && b.empreendcobritem_tp_cobr !== "Item") {
          return -1;
        } else if (a.empreendcobritem_tp_cobr !== "Item" && b.empreendcobritem_tp_cobr === "Item") {
          return 1;
        } else {
          return 0;
        }
      });
    },

    saveItem (item) {
      this.contaPagarForm.cob_item.push({ ...item })
      console.log("🚀 ~ file: ContasPagarModal.vue:1335 ~ saveItem ~ this.contaPagarForm.cob_item:", this.contaPagarForm.cob_item)
      this.ordenaItens()
      this.$emit("atualizaItem", { ...this.contaPagarForm.cob_item })
    },

    editItem(item) {
      console.log("🚀 ~ file: ContasPagarModal.vue:1340 ~ editItem ~ this.contaPagarForm.cob_item:", this.contaPagarForm.cob_item)
      this.$set(this.contaPagarForm.cob_item, item.indice, { ...item })
      // this.ordenaItens()

      this.selectedEditItem = {}
    },

    getItemValueColor (item) {
      // console.log("🚀 getItemValueColor:", item.item_cre_deb)
      return item.item_cre_deb === 'C' ? 'green--text' : 'red--text'
    },

    selecionaDevedor(){
      if(!this.contaPagarForm?.obj_devedor_pagador?.cod_pessoa)
        return

      if(this.contaPagarForm.cobr_pess.cod_pessoa === this.contaPagarForm.obj_devedor_pagador.cod_pessoa){
      this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'O Favorecido não pode ser o mesmo que o Pagador!'
        this.store_site.alert           = true;
        this.contaPagarForm.obj_devedor_pagador = null
        this.array_devedor = []
        return
      }
      this.devedor_pagador_aux = this.contaPagarForm.cobr_pag;
      this.contaPagarForm.cod_devedor_pagador = this.contaPagarForm.obj_devedor_pagador.cod_pessoa
     
      this.$nextTick(() => {
        if( (this.contaPagarForm.cod_devedor_referencia !== this.contaPagarForm.cod_devedor_pagador) && this.contaPagarForm.cobr_pagto.length > 0){
          this.dialog_mudou_pagador = true
        }
      })
    },

    busca_timeout(val, tipo) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(async () => {
        const ls_Rel_Devedor = await API.get(`pessoa`, val);
        if (Array.isArray(ls_Rel_Devedor.data?.result?.rows)) {
          if(tipo === 'devedor'){
            this.array_devedor = ls_Rel_Devedor.data?.result?.rows || [];
          } else if(tipo === 'favorecido'){
            this.array_favorecido = ls_Rel_Devedor.data?.result?.rows || [];
          }
        }
      }, 600);
    },

    querySearch(query, tipo){
      let p_params = { params: { filtro: query } }
      this.loading = true
      this.busca_timeout(p_params, tipo);
      this.loading = false

    },  

    async buscaFases(){
      if(this.dados_editar.cod_empreendimento){
        let {data} = await API.get(`fase_empreendimento`, { params: { cod_empreendimento : this.dados_editar.cod_empreendimento } });
        this.array_fases = data.result
      }
    },
   
    validateDate() {
      const isValidDate = moment(this.contaPagarForm?.nova_dt_venc?.substring(0,10), 'YYYY-MM-DD', true).isValid();
      return isValidDate
    },
   
    atualizaArrayPagador(data){
      this.pagadorObj.pessoa_contas.forEach((conta, indexConta) => {
        conta.fin_cheque.forEach((cheque, indexCheque) => {
          data.cheques.forEach(dataCheque => {
            if (dataCheque.cod_fin_cheque === cheque.cod_fin_cheque) {
              cheque = dataCheque;
              this.$set()
            }
          });
        });
      });
    },

    toggleSelectClientDialog () {
      // VARIAVEL AUXILIAR PARA LIMPAR AS FORMAS DE PAGAMENTO SE NECESSARIO
      this.devedor_pagador_aux = this.contaPagarForm.obj_devedor_pagador;

      this.dialogPagador = !this.dialogPagador
    },

    mudouPagadorNao(){
      this.array_devedor = []
      this.array_devedor.push(this.devedor_pagador_aux)
      this.contaPagarForm.obj_devedor_pagador = this.devedor_pagador_aux
      this.contaPagarForm.cod_devedor_pagador = this.devedor_pagador_aux.cod_pessoa
      this.dialog_mudou_pagador = false
    },

    mudouPagadorSim(){
      // limpando as formas de pagamento de possuem cod_pessoaconta
      const newPaymentTableData = [];

      // this.array_devedor = []
      // this.contaPagarForm.obj_devedor_pagador = null

      for (let i = 0; i < this.contaPagarForm.paymentTableData.length; i++) {
        if (!this.contaPagarForm.paymentTableData[i].cod_pessoaconta) {
          newPaymentTableData.push(this.contaPagarForm.paymentTableData[i]);
        }
      }

      this.contaPagarForm.paymentTableData = newPaymentTableData;
      this.dialog_mudou_pagador = false
    },

    selectPagador () {
      if(this.contaPagarForm.cobr_pess.cod_pessoa === store_Pessoa.pessoa_selecionado.cod_pessoa){
        this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = 'O Favorecido não pode ser o mesmo que o Pagador!'
          this.store_site.alert           = true;
          return
      }
      this.devedor_pagador_aux = this.contaPagarForm.obj_devedor_pagador;
      
      this.contaPagarForm.pagador             = store_Pessoa.pessoa_selecionado.pessoa_nome
      this.contaPagarForm.cod_devedor_pagador = store_Pessoa.pessoa_selecionado.cod_pessoa

      this.array_devedor = []
      this.array_devedor.push(store_Pessoa.pessoa_selecionado)
       
      this.contaPagarForm.obj_devedor_pagador =  { cod_pessoa :  this.contaPagarForm.cod_devedor_pagador, nome_pessoa : this.contaPagarForm.pagador}
      this.dialogPagador = false
      
      this.$nextTick(() => {
        if( (this.contaPagarForm.cobr_pess.cod_pessoa !== store_Pessoa.pessoa_selecionado.cod_pessoa) && this.contaPagarForm.cobr_pagto.length > 0){
          this.dialog_mudou_pagador = true
        }
      })
    },

    toggleDialogFormasPagamento (item) {
      this.selectedCobranca = item
      this.dialogFormasPagamento = !this.dialogFormasPagamento
    },

    getTipoPagamento (item) {
      return store_ContasPagar?.opcoesFormaDePagamento?.find(opcao => opcao.id == item.cod_fin_tipo_pagamento)?.text
    },

    fechar() {
      if(this.contaPagarForm.empreendcobr_situacao === 'Aberto'){
        this.dados_editar.cobr_pagto = []
      } 
      this.$emit('update:dialog_novo_editar', null);
    },

    saveFormaDePagamento (item) {
      let cheque_repetido = 0
      if(item.cod_fin_cheque > 0){
        this.contaPagarForm.paymentTableData.map(value => {
          if (!value.cod_empreendcobranca_pagto)
            value.cod_empreendcobranca_pagto = null;
          if(value.cod_fin_cheque == item.cod_fin_cheque) {
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = 'Este cheque já foi utilizado anteriormente!';
            this.store_site.alert           = true;
            cheque_repetido ++
            return
          }
        })
      }
      if (cheque_repetido == 0) {
        this.contaPagarForm.paymentTableData.push(item)
      }
    },  

    verificarRepasse(){
      if (!this.isPagadorEqualToDevedor) {
        this.dialog_criar_repasse = true
      }else{
        this.pagar()
      }
    },

    async pagar() {
      // Permite pagar com valor menor, neste caso a parcela não é baixada, apenas aceita a forma de pagamento
      // if (this.valorPago.toFixed(2) < this.pagamentoValorFinal.toFixed(2)) {
      //   this.store_site.alert_cor       = "#FF0000";
      //   this.store_site.alert_timeout   = 10000;
      //   this.store_site.alert_msg       = 'O valor recebido não pode ser menor que o valor da parcela!';
      //   this.store_site.alert           = true;
      //   return
      // }

      if (!!this.contaPagarForm.empreendcobr_parc_acres_obs && !this.contaPagarForm.empreendcobr_parc_acres_valor ) {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'Quando a Descrição Acréscimo estiver preenchida o Valor Acréscimo é obrigatório!';
        this.store_site.alert           = true;
        return
      }

      if (!!this.contaPagarForm.empreendcobr_parc_desc_obs && !this.contaPagarForm.empreendcobr_parc_desc_valor ) {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'Quando a Descrição Desconto estiver preenchida o Valor Desconto é obrigatório!';
        this.store_site.alert           = true;
        return
      }
      this.loading = true

      // Somente baixa a cobrança se valor de pagamento for maior ou igual ao valor final da parcela
      if (this.valorPago.toFixed(2) >= this.pagamentoValorFinal.toFixed(2)) {
        this.contaPagarForm.empreendcobr_situacao         = "Pago"
        //this.contaPagarForm.empreendcobr_pagto_valor      = this.valorPago
        this.contaPagarForm.empreendcobr_parc_valor_final = this.pagamentoValorFinal
        this.contaPagarForm.empreendcobr_pagto_valor      = this.pagamentoValorFinal
      }
      this.contaPagarForm.paymentTableData.forEach(objeto => {
        objeto.cod_pessoaconta = objeto?.cod_pessoaconta?.cod_pessoa_conta;
      });

      this.contaPagarForm?.cob_item.map(value => {
        if(value.empreendcobritem_tp_cobr == 'Multa'){
          this.contaPagarForm.empreendcobr_pagto_valor_multa = value.empreendcobritem_valor
        }
        else if(value.empreendcobritem_tp_cobr == 'Juros'){
          this.contaPagarForm.empreendcobr_pagto_valor_juros = value.empreendcobritem_valor
        }
        else if(value.empreendcobritem_tp_cobr == 'Multa / Juros'){
          this.contaPagarForm.empreendcobr_pagto_valor_muljur = value.empreendcobritem_valor
        }
        else if(value.empreendcobritem_tp_cobr == 'Acréscimo'){
          this.contaPagarForm.empreendcobr_parc_acres_valor = value.empreendcobritem_valor
          this.contaPagarForm.empreendcobr_parc_acres_descr = 'Acréscimo'
        }
        else if(value.empreendcobritem_tp_cobr == 'Desconto'){
          this.contaPagarForm.empreendcobr_parc_desc_valor = value.empreendcobritem_valor
          this.contaPagarForm.empreendcobr_parc_desc_descr = 'Desconto'
        }
      })

      // está variavel dentro do objeto chamado ação, controla se vai ser tratado como array pra pagamento em lote
      // e  a outra sem a acao vai ser alterada no banco a cada item alterado
      if (this.contaPagarForm.acao !== 'lote') {
        // console.log('dados', this.contaPagarForm)
        // return
        const response = await store_ContasPagar.ContasPagarPagar({ ...this.contaPagarForm, cobr_pagto: this.contaPagarForm.paymentTableData })
        
        if (response.data.result.status == 200) {
          this.$emit('atualizaRegistro', this.contaPagarForm)

          this.$emit('busca', true)
  
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = response.data.result.result.trim();
          this.store_site.alert           = true;
        } else {
          // Mensagem de exclusão não realizada
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = response.data.result.result.trim();
          this.store_site.alert           = true;
        }
      }
      else{
        const response = await API.post(`/erp/contaspagar/contaspagar_pagar_lote`, JSON.stringify(this.contaPagarForm));

        if (response.data.result.status == 200) {
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = response.data.result.result.trim();
        this.store_site.alert           = true;
      } else {
        // Mensagem de exclusão não realizada
        this.loading = false
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = response.data.errors.trim()
        this.store_site.alert           = true;
        return
      }
        this.$emit('atualizaRegistro', this.contaPagarForm)
        this.$emit('contadorCarrinho')

      }

      this.dialog_criar_repasse = false
      this.loading = false
      this.dialog_GedNovoDocumentoModal = true
      // this.fechar()

    },

    openModalExcluirFormaDePagamento (item) {
      this.selectedFormaDePagamento = item
      this.modalExcluirFormaDePagamento = true
    },

    async excluirFormaDePagamento () {
      this.loading_excluir_forma_pagto = true
      try {
        let i = this.contaPagarForm.paymentTableData.indexOf(this.selectedFormaDePagamento);
        if (i !== -1) {
          this.contaPagarForm.paymentTableData.splice(i, 1);
        }

        this.$emit('atualizaRegistro', this.contaPagarForm)
        this.loading_excluir_forma_pagto = false 
        this.modalExcluirFormaDePagamento = false
      } catch (err) {
        console.log(err)
      }
    }
  }
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  font-size: 19px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.title-page-menor {
  font-family: "Open Sans", sans-serif!important;
  font-size: 16px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 580px;
  overflow-y: auto;
}

.row-value {
  margin-top: -146px;
}

.btn {
  width: 100px;
}

.v-divider {
  background: var(--COR_PRINCIPAL)!important;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

</style>

<style scoped lang="scss">
.input-text {
    ::v-deep {
        .v-text-field {
            input {
                font-size: 19px;
            }
        }
    }
}

.pagar-form {
  &::-webkit-scrollbar {
    display: none;
  }
}
.linha {
    display: flex;
    align-items: center;
}

.texto-verde {
    color: green;
    margin: 0 5px; /* Espaço entre o texto e as linhas horizontais */
    font-size: 17px;
}

.linha-horizontal {
    flex-grow: 1; /* As linhas horizontais se expandirão para ocupar o espaço restante */
    border: none;
    border-top: 0.5px solid #E0E0E0; /* Cor e espessura das linhas horizontais */
}

.contas-pagar-modal {
  // height: 600px;

  // &__card {
  //   height: 100%;
  // }
  &__item-value-input.no-discount input {
    color: #D32F2F !important; 
  }

  &__item-value-input.has-discount input {
    color: #388E3C !important;
  }
}
</style>