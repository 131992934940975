<template>
  <div id="ContasPagarCodigoBarras">
    <v-card elevation="0" class="pa-0" style="border-radius: 0;">
      <v-card-title
        :style="{ 'background-color':COR_PRINCIPAL }"
        class="justify-space-between py-0 px-0">
        <div>
          <label
            class="ml-2"
            style="color:silver;font-size:14px;font-style: bold;">
            Cobrança nº:
          </label>
          <label
            style="color:white;font-size:15px;"
            class="mr-4">
              {{ dados_documentos.cod_empreendcobranca }} 
          </label>
          <label
            style="color:silver;font-size:14px;font-style: bold;">
            Favorecido:
          </label>
          <label
            style="color:white;font-size:15px;"
            class="mr-4">
              {{ dados_documentos.cobr_pess.pessoa_nome }} 
          </label>
          <label style="color:white;font-size:15px;" class="mr-4">|</label>
          <label
            style="color:silver;font-size:14px;font-style: bold;">
            Empreendimento:
          </label>
            <label
            style="color:white;font-size:15px;"
            class="mr-5">
            {{dados_documentos.emp_cobr?.empreend_nome}}
          </label>
        </div>
        <v-btn @click="fechar()" icon dark color="#F2F6F7">
          <v-icon large class="title">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="wrapper pa-0 pagar-form" >
        <v-divider dark></v-divider>
      </v-card-text>
    </v-card>
    <v-card>
      <iframe
        class="altura"
        :src="baseURL + 'imagens/ged/empresa_' + array_pagar_cod_barra[0].cod_empresa + '/cobranca/' + array_pagar_cod_barra[0].imagem_caminho"
        frameborder="0"
        style="overflow:hidden;height:73vh;width:100%"
        height="80%"
        width="100%">
      </iframe>
  
      <v-footer
        :style="{ 'background-color':COR_SECUNDARIA, 'bottom' : '-10px' }"
        
        elevation="0"
        class="d-flex justify-center pa-3 mb-2">
        <v-col cols="4" class="pa-0 mr-3">
          <v-select 
            label="Contas" 
            v-model="contaPagarForm.conta" 
            :items="array_opcoes_contas"
            filled 
            light 
            hide-details
            outlined
            dense 
            return-object
            background-color="#FFF" />
        </v-col>
        <v-col cols="2" class="pa-0 mr-3">
          <!-- <VuetifyMoney
            v-model="contaPagarForm.multa_juros"
            filled
            dense
            id="multa_juros"
            class="input-text"
            hide-details
            append-icon="mdi-currency-usd"
            outlined
            @click="dialog_item_novo = true; selectedEditItem = {}; empreendcobritem_tp_cobr = 'Multa / Juros'"
            background-color="#FFF"
            placeholder="Multa / Juros"
            label="Multa / Juros"
            :clearable="false"
            readonly /> -->
          <v-text-field
            label="Multa / Juros" 
            v-model="contaPagarForm.multa_juros" 
            id="multa_juros"
            class="input-text"
            filled 
            @click="dialog_item_novo = true; selectedEditItem = {}; empreendcobritem_tp_cobr = 'Multa / Juros'"
            append-icon="mdi-currency-usd"
            light 
            hide-details
            outlined
            readonly
            dense 
            background-color="#FFF" />
        </v-col>
        <v-col cols="2" class="pa-0 mr-3">
          <!-- <v-text-field 
            label="Valor Cobrado" 
            v-model="contaPagarForm.valor_cobrado" 
            filled 
            light 
            hide-details
            outlined
            readonly
            dense 
            background-color="#FFF" /> -->
          <VuetifyMoney
            v-model="contaPagarForm.valor_cobrado"
            filled
            hide-details
            dense
            class="input-text"
            outlined
            background-color="#FFF"
            placeholder="Valor Parcela"
            label="Valor Parcela"
            :clearable="false"
            readonly />
        </v-col>
        
        <v-btn
        @click="fechar()"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>
  
        <v-btn
        :disabled="!contaPagarForm.conta"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
       <!-- DIALOGO ITENS -------------------------------------------------------->
    <v-container v-if="dialog_item_novo">
      <v-dialog
        persistent
        v-model="dialog_item_novo"
        transition="dialog-bottom-transition"
        max-width="600"
        min-width="520">
        <ContasPagarNovoItemModal
          v-if="dialog_item_novo"
          :dialog_item_novo.sync="dialog_item_novo"
          :selectedEditItem="selectedEditItem"
          :valorParcela="valorParcela"
          :codCobranca="contaPagarForm.cod_empreendcobranca"
          :empreendcobritem_tp_cobr="empreendcobritem_tp_cobr"
          @save-item="saveItem" />
      </v-dialog>
    </v-container>
    <!-- FIM DIALOGO ITENS ---------------------------------------------------->
  </div>
</template>

<script>
import store_Ged from "../../Ged/store_Ged"
import store_site from "../../../store/store_site"
import VuetifyMoney from "../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
import ContasPagarNovoItemModal from "./ContasPagarNovoItemModal.vue"
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO } from "../../../services/constantes";
import {formatDateTime, resizeImage, blobToBase64, groupArrayMultKeys, generatePDFThumbnails } from "../../../services/funcoes";
import { baseURL, API } from "../../../services/API";
import moment from 'moment'

export default {
  name: "ContasPagarCodigoBarras",

  props: [
    "cod_empreendcobranca_aux", 
    "dados_documentos",
    "array_pagar_cod_barra"
  ],


  components: {
    ContasPagarNovoItemModal,
    VuetifyMoney
  },

  data() {
    return {
      store_Ged         : store_Ged,
      store_site        : store_site,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    : COR_SECUNDARIA,

      baseURL           : baseURL,

      filtro             : null,
      panel              : [],
      tamanhoArray       : [],
      // search             : null,
      // search_input_focus : false,
      arrayTipoDocumento : null,
      array_opcoes_contas : [],
      vencimento_visivel : null,
      image              : null,
      loading_salvar     : false,
      loading_excluir    : false,
      resizedImg         : null,
      tipo_documento_aux : null,
      tipo_Aux           : null,
      cadastro_dt_Aux    : null,
      dialog_excluir     : false,
      dialog_ver_imagem_documento : null,
      tipo_arquivo_aux            : null,
      dialog_item_novo            : false,
      contaPagarForm: {
        conta : null,
        cob_item : [],
        empreendcobr_pagto_valor_muljur : null,
      },
    };
  },

  mounted() {
    this.opcoesContas()
    this.valorParcela = this.dados_documentos.empreendcobr_parc_valor
    this.contaPagarForm.valor_cobrado = this.dados_documentos.empreendcobr_parc_valor
    this.contaPagarForm.cod_empreendcobranca = this.dados_documentos.cod_empreendcobranca

    this.contaPagarForm.cob_item = this.dados_documentos.cob_item
    let elemento = document.querySelector('#multa_juros')
    elemento.style.cursor = 'pointer';
    elemento.style.color = '#A6A6A6'
  },
  
  methods: {
    saveItem (item) {
      this.contaPagarForm.multa_juros = item.empreendcobritem_valor

      var i = this.contaPagarForm.cob_item.findIndex(val => val.empreendcobritem_tp_cobr == "Multa / Juros")

      if(i >= 0){
        this.contaPagarForm.cob_item.splice(i, 1)
      }
     
      this.contaPagarForm.cob_item.push({ ...item })
      this.contaPagarForm.valor_cobrado += Math.abs(item.empreendcobritem_valor) 
      console.log("🚀 ~ file: ContasPagarCodigoBarras.vue:257 ~ saveItem ~ this.contaPagarForm:", this.contaPagarForm)
     
      this.$emit("atualizaRegistro", { ...this.contaPagarForm.cob_item })
    },

    async opcoesContas() {
      // console.log("🚀 ~ file: ContasPagarCodigoBarras.vue:197 ~ opcoesContas ~ opcoesContas:", this.dados_documentos.cobr_dev.cod_pessoa)
      // return
      
      const {data} = await API.get('/conta_pessoa/',
      { 
        params: { cod_pessoa : this.dados_documentos.cobr_dev.cod_pessoa } 
      })

      var array_contas_encontradas = []
      var array_conta_dinheiro = []
      var array_cartao_credito = []
      
      data?.result.forEach((elemento) => {
        if (elemento.pessoa_conta_tipo === "B") {
          array_contas_encontradas.push(elemento);
        }
        else if (elemento.pessoa_conta_tipo === "D") {
          array_conta_dinheiro.push(elemento);
        }
        else if (elemento.pessoa_conta_tipo === "CC") {
          array_cartao_credito.push(elemento);
        }
       
      });
      
      this.opcoes_caixa_interno = array_conta_dinheiro

      this.opcoes_cartao_credito = array_cartao_credito
      this.array_contas_original = array_contas_encontradas
      this.array_opcoes_contas = array_contas_encontradas?.map(conta => {
        return {
                id: conta.cod_pessoa_conta, 
          text: `${conta.pessoa_conta_agencia.banco_compensacao} - 
                 ${conta.pessoa_conta_agencia.banco_nome} - 
                 ${conta.pessoa_conta_agencia.agencia_numero} - 
                 ${conta.pessoa_conta_numero}${conta.pessoa_conta_numero_dig ? '-' + conta.pessoa_conta_numero_dig : ''}`, 
          value: conta.cod_pessoa_conta 
        }
      })
    },

    fechar(){
      this.$emit("fecharModal", false);

    },

    async validate() {
      if(!this.contaPagarForm.conta){
        this.store_site.alert_timeout = 10000;
        this.store_site.alert_cor = "red";
          this.store_site.alert_type = "warning";
          this.store_site.alert_msg = 'A conta é obrigatória';
          this.store_site.alert = true;
          return
      }

      this.contaPagarForm.cod_empreendcobranca = this.dados_documentos.cod_empreendcobranca
      this.contaPagarForm.empreendcobr_situacao = "Pago"

      this.contaPagarForm.cob_item.forEach(val => {
        if(val.empreendcobritem_tp_cobr != "Item"){
          val.cod_empreendcobranca = this.dados_documentos.cod_empreendcobranca
          // val.empreendcobr_pagto_valor = this.dados_documentos.empreendcobr_parc_valor_final + Math.abs(parseFloat(this.contaPagarForm.cob_item[0]?.empreendcobritem_valor))
          // val.empreendcobr_pagto_valor_muljur = Math.abs(parseFloat(this.contaPagarForm.cob_item[0]?.empreendcobritem_valor)) || null

        }
      })

      var total_item = this.contaPagarForm.cob_item.reduce((total, item) => total + parseFloat(item.empreendcobritem_valor), 0);
      this.contaPagarForm.empreendcobr_pagto_valor_muljur = this.contaPagarForm.multa_juros
      this.contaPagarForm.empreendcobr_pagto_valor = total_item
      this.contaPagarForm.empreendcobr_parc_valor_final = total_item

      this.contaPagarForm.empreendcobr_pagto_dt = moment().format()
      this.contaPagarForm.cobr_pagto = []
      this.contaPagarForm.cobr_pagto.push({
        cod_empreendcobranca : this.contaPagarForm.cod_empreendcobranca,
        empreendcobrpagto_valor : this.contaPagarForm.empreendcobr_pagto_valor,
        cod_pessoaconta : this.contaPagarForm.conta.id,
        empreendcobrpagto_dt_cadastro : moment().format(),
        empreendcobrpagto_descr : this.contaPagarForm.conta.text + ' - pago com cód. de barras',
        cod_fin_tipo_pagamento : 9
      })
      delete this.contaPagarForm.conta
      // console.log("🚀 ~ file: ContasPagarCodigoBarras.vue:347 ~ validate ~ this.contaPagarForm:", this.contaPagarForm)
      // return
      const response = await API.post(`/erp/contaspagar/contaspagar_pagar_lote`, JSON.stringify(this.contaPagarForm));

      if (response.data.result.status == 200) {
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = response.data.result.result.trim();
        this.store_site.alert           = true;
      } else {
        // Mensagem de exclusão não realizada
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = response.data.errors.trim()
        this.store_site.alert           = true;
        this.loading = false
        return
      }
      
      this.loading_salvar = true;
      this.$emit('atualizaRegistro', this.contaPagarForm)
      this.fechar()

    },

  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
};
</script>

<style scoped>
#ContasPagarCodigoBarras {
  /* height: calc(100vh - 100px); */
  overflow: hidden;
  background-color: var(--COR_SECUNDARIA);
}

/* ---------- BARRA DE SCROLL ---------- */
#ContasPagarCodigoBarras::-webkit-scrollbar {
  width: 5px;
}

#ContasPagarCodigoBarras::-webkit-scrollbar-button {
  padding: 1px;
}

#ContasPagarCodigoBarras::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#ContasPagarCodigoBarras::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 100px;
} 

.title-page {
  font-family: "Open Sans", sans-serif!important;
  letter-spacing: 0px;
  font-size: 18px;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn {
  width: 100px;
}

.search-input, .search-input-longo {
  margin: 0 3% 1.2rem 0;
  height: 35px !important;
  max-width: 400px!important;
  min-width: 400px!important;
}

@media(max-width: 1024px) {
 .search-input, .search-input-longo {
    max-width: 250px!important;
    min-width: 250px!important;
  }
}

@media (max-width: 768px) {
  .headline {
    flex-direction: column;
  }

  .container_btn-header {
    margin-left: 0px!important;
  }

  .search-input {
    margin: 0.8rem 3% 1.2rem 0;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px)!important; */
    /* max-width: calc(100% - 15px)!important; */
  }
}

@media(max-width: 599px) {
  .search-input {
    margin: 0.8rem 20px 1.2rem 0;
    min-width: auto!important;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px)!important; */
  }

  .container_btn-header .caption {
    margin-top: 16px!important;
  }
}
  
.select-order {
  max-width: 210px;
}

@media(max-width: 768px) {
  .container_btn-header .caption {
    margin-top: 12px!important;
  }

  .select-order {
    margin-top: 12px!important;
    margin-right: 0px!important;
  }
}

@media(max-width: 599px) {
  .select-order-desktop {
    display: none;
  }

  .select-order {
    max-width: 50px;
  }

  .container_btn-header .caption {
    margin-top: 16px!important;
  }
}

.col-wrapper {
  max-width: 196px;
}

@media(max-width: 1024px) {
  .col-wrapper {
     max-width: 30%!important;
  }
}

@media(max-width: 768px) {
  .col-wrapper {
     max-width: 45%!important;
  }
}

@media(max-width: 599px) {
  .col-wrapper {
     max-width: 46%!important;
  }
}

.col-wrapper .card:last-child {
  margin-right: 0px!important;
}

.col-wrapper {
  position: relative;
}

.card-image {
  height: 250px;
}

@media(max-width: 599px) {
  .card-image {
    height: 150px;
  }
}

.document-name {
  max-width: 20ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

@media(max-width: 1024px) {
  .document-name {
    max-width: 15ch;
  } 
}

@media(max-width: 599px) {
  .document-name {
    max-width: 8ch;
  } 
}

@media(max-width: 375px) {
  .document-name {
    max-width: 6ch;
  } 
}

@media(max-width: 360px) {
  .document-name {
    max-width: 5ch;
  } 
}

.container_btn-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0!important;
  visibility: hidden!important;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  background: rgba(25, 118, 210, 0.2); 
}

.col-wrapper:hover .container_btn-icon {
  visibility: visible!important;
  opacity: 1!important;
}

.v-dialog {
  overflow: hidden;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  font-size: 19px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.title-page-menor {
  font-family: "Open Sans", sans-serif!important;
  font-size: 16px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}
.btn-icon {
  width: 45px;
  height: 45px;
  background: rgb(255, 255, 255, 0.7);
  transition: 0.3s;
}

@media(max-width: 599px) {
  .btn-icon {
    width: 30px;
    height: 30px;
  }
}

.v-divider {
  background: var(--COR_PRINCIPAL)!important;
}
.btn-icon:hover {
  background: #FFF;
}

.icon-action {
  font-size: 30px!important;
}

@media(max-width: 599px) {
  .icon-action {
    font-size: 20px!important;
  }
}

.divisor {
  height: 1px;
  border-color: #C0C0C0;
}

.theme--light.v-input input{
  color: #A6A6A6 !important;
}

</style>
<style scoped lang="scss">
.input-text {
    ::v-deep {
        .v-text-field {
            input {
                color: #A6A6A6;
            }
        }
    }
}

</style>